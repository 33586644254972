import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import RetryButton from '../components/RetryButton'

import LoginSubmissionForm from '../components/Forms/loginSubmissionForm'

import SeminarSummary from '../components/SeminarSummary'
import ProductList from '../components/ProductList'



import {
	Breadcrumb,
	Button,
	Icon,
	Container,
	Item,
	Image,
	Header,
	Message,
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import * as contentpagestyles from './styles/contentpage.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth,
	confirmationColor
} from '../globalVar'



const Negosyo = require('../../lib/negosyo')



class SeminarContentPageTemplate extends React.PureComponent {
	state = {
		loggedIn:undefined,
		pageid: 0,
		record: this.props.data.negosyoNow.viewSeminar,
		recorddetails: {},
		success: "",
		pageLoading:true,
		error:false,
		shown:undefined,
		timeOut1:undefined,
		username: "",
		hasRegistered: false,
		pageSection:[],

		mobile:undefined,
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				shown: 5,
				mobile:false,
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
				mobile:true,
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true,
			})
		}
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}
	componentDidMount() {
		this.handler();
		this.fetchData();
		window.addEventListener('resize', this.resizeTimerFunc);
	}

	handleSignIn = (username) => {
		// Check if already registered
		if (username.length > 0) {
			this.setState({
				username: username,
			}, function() {
				(async () => {
					const response = await Negosyo.getSeminarResources(username, this.props.pageContext.url);
					if (response.hasOwnProperty("registered") && response.hasOwnProperty("content")) {
						this.setState({
							hasRegistered: response.registered,
							recorddetails: response.content,
							pageLoading: false
						});
					} else {
						this.invalidAccess();
					}
				})();
			});
		}
	}

	returnPage = () => {
		navigate('/seminar/'+this.props.pageContext.url);
	}

	downloadFile = (index, fname) => {
		if (fname.indexOf("://") > 0) {
			window.open(fname, "_blank");
		} else {
			this.setState({
				["downloading"+index]: true,
			}, function () {
				setTimeout(function() {
					this.setState({
						["downloading"+index]: false,
					});
				}.bind(this), 5000);
				(async () => {
					await Negosyo.getSeminarFile(this.state.username, this.props.pageContext.url, fname);
				})();

			});
		}
	}

	fetchData = (retry=false) => {
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.handleSignIn(user.username);
				}).catch((err)=>{
					this.invalidAccess();
				})
			} else {
				this.invalidAccess();
			}
		}).catch((err)=>{
			this.invalidAccess();
		})
	}

	invalidAccess = () => {
		this.setState({
			pageLoading:false
		})
	}


	render() {
		const {
			record,
			error,
			pageLoading,
			hasRegistered
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this

		var hascompleted = false;
		let schedulestr = ""
		if (record.datestart) {
			var curdatestr = new Date();
			const startdateobj = new Date(record.datestart);
			schedulestr = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(startdateobj);
			if (record.datestart.substring(0,10) < curdatestr.toISOString().substring(0, 10)) {
				hascompleted = true;
			}

		}


		return (

			<Layout location={location} backButton={true}>
				<Container text className={styles.container}>
					<SEO
						canonicalpath={"/seminar/resource/"+record.url}
						title={record===undefined ? " Resouces Page":record.name}
						image={record.mainImageHref}
						twitterCard={"summary"}
						description={record.summary}
						meta={[{name: `robots`, content: `noindex`}]}
					/>
					<span id="page-top" className={styles.anchor}/>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={"Seminars"} as={Link} to={"/seminar/"} />
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={record.name+" Resources"} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						<Breadcrumb.Section as={Link} to={"/seminar/"} >
							<Icon name="arrow left" />
							{"Seminars"}
						</Breadcrumb.Section>
					</Breadcrumb>
					<br/>
					{record.url !== "" &&
						<SeminarSummary
							{...record}
							subtitle={"Resouces Page"}
							recorddetails={this.state.recorddetails}
							hasRegistered={hasRegistered}
						/>
					}

					{record.url !== "" &&
						<React.Fragment>
							<Item.Group>
								<Item className={styles.item}>
									<Item.Content>
										{hasRegistered &&
											<>
											{(this.state.recorddetails.contentlist.length < 1 && this.state.recorddetails.productlist.length < 1)?
												<>
													<br/>
													<>No Content at the moment</>
													<br/>
												</>
											:
												<><br/><hr/><br/></>
											}
											</>
										}
									</Item.Content>
								</Item>
							</Item.Group>
						</React.Fragment>

					}
					{( pageLoading===true ) && (
						<Message warning className={styles.msg}>
							<Message.Header>
								Loading...
							</Message.Header>
						</Message>
					)}
					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									pageLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Seminar Content"}
						/>
					)}
					{( hasRegistered===false && pageLoading===false ) &&
						<>
						{this.state.username.length > 0 ?
							<Message warning className={styles.msg}>
								<Message.Header>
									You are not yet registered.
								</Message.Header>
								<Link to={"/seminar/"+this.props.pageContext.url+"/"}>Click to Register</Link>
							</Message>
						:
							<LoginSubmissionForm
								noUserInfo={true}
								handleSignIn={this.handleSignIn}
							/>
						}
						</>
					}
				</Container>
				{hasRegistered && <>

					{this.state.recorddetails.contentlist.length > 0 &&
						<Container className={styles.container}>
							<Header className={`${styles.header}`}>
								Downloads/Files
							</Header>
							<ul>
								{this.state.recorddetails.contentlist.map((curfile, index)=>{
									if (curfile.url.length < 1) {
										return <li className={styles.header+" "+contentpagestyles.contentsectiontitle}>{curfile.name}</li>
									}
									const filenamelink =  <li>
													{curfile.name}
													&nbsp;&nbsp;&nbsp;
													{this.state['downloading'+index] === true ?
														<>
															...
														</>
													:
														<Button
															icon={"download"}
															onClick={()=>{
																this.downloadFile(index, curfile.url);
															}}
														/>
													}
												</li>
									if (curfile.hasOwnProperty("thumbnail")) {
										if (curfile.thumbnail.length>0) {
											return <li className={contentpagestyles.contentthumbnail}>
												<Image
													src={curfile.thumbnail}
												/>
												{filenamelink}
											</li>
										}
									}
									return {filenamelink}
								})}
							</ul>
						</Container>
					}
					{this.state.recorddetails.productlist.length > 0 &&
						<Container className={styles.container}>
							<Header className={`${styles.header}`}>
								Exclusive Items
							</Header>
							<ProductList
								products={this.state.recorddetails.productlist}
								shown={this.state.shown}
								grid={16}
								cameFrom={`seminar-${this.state.record.name}`}
								page={0}
								offset={20}
								listName={`Seminar-${this.state.record.name}`}
							/>
						</Container>

					}

				</>}
			</Layout>
		)
	}
}

export default SeminarContentPageTemplate


export const data = graphql`
	query SeminarContentQuery($url:String!) {
		negosyoNow {
			viewSeminar( url: $url ) {
				id
				name
				summary
				url
				mainImageHref
				slug
				organizationName
				organizationUrl
				sku
				datestart
				exclusive
				available
				link
				linkdetail
				fees
			}
		}
	}
`

